@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "ElzaText";
    src: url("/fonts/ElzaText-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "ElzaText-Oblique";
    src: url("/fonts/ElzaText-Oblique.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "ElzaText";
    src: url("/fonts/ElzaText-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "ElzaText";
    src: url("/fonts/ElzaText-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 255, 255, 255;
  --background-end-rgb: 255, 255, 255;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

body {
  color: rgb(var(--foreground-rgb));
  transition: all 0.3s;
  /* background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb)); */
  font-family: "ElzaText", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

html {
  font-size: 16px;
}

.min-safe-h-screen {
  min-height: calc(100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0));
}

@supports (-webkit-touch-callout: none) {
  .min-safe-h-screen {
    min-height: -webkit-fill-available;
  }
}

@media screen and (min-width: 1660px) {
  html {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@keyframes spin-slow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spin-slow {
  animation: spin-slow 6s linear infinite;
}

.item ::selection {
  background-color: #000694;
  color: white;
}

body ::selection {
  background-color: #fff96b;
  color: black;
}
